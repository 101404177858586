body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {  
  font-family: 'Poppins', sans-serif !important;
}

a {
  color: #178082!important;
}
